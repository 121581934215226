import { render, staticRenderFns } from "./DriverApproval.vue?vue&type=template&id=bee06326&scoped=true&"
import script from "./DriverApproval.vue?vue&type=script&lang=js&"
export * from "./DriverApproval.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bee06326",
  null
  
)

export default component.exports