<template>
    <div>
        <div class="main-wrapper">
            <!--begin::sidebar-->
            <sidebar-component classMenu="GerbangTolAdd"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->

                <!--begin::content-->
                <div class="page-content">
                    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                                    &nbsp;&nbsp;&nbsp;
                                    <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                                        Check Driver
                                    </h6>
                                    <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                                        <i class="link-icon" data-feather="repeat"></i>
                                        {{ $t("resetFormTxt") }}
                                    </button>
                                </div>
                                <div class="card-body">
                                    <!--begin::loading-data-->

                                    <!--end::loading-data-->

                                    <form class="forms-sample" @submit.prevent="loadItems" method="POST">
                                        <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">SIM No</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <input type="text" class="form-control" id="no_rekening" name="sim"
                                                    placeholder="" v-model="forms.sim_no" />

                                                <div v-if="errors.sim_no">
                                                    <div v-for="error in errors.sim_no" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">ID Card</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <input type="text" class="form-control" id="no_rekening"
                                                    name="id_card_number" placeholder=""
                                                    v-model="forms.id_card_number" />

                                                <div v-if="errors.sim_no">
                                                    <div v-for="error in errors.id_card_number" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>











                                        <div class="row mb-3">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">Driver Name</label>
                                            </div>

                                            <div class="col-lg-4">
                                                <input type="text" class="form-control" id="driver_name"
                                                    name="driver_name" placeholder="" v-model="forms.driver_name" />


                                            </div>
                                        </div>

                                        <div class="row mb-3" v-if="result">
                                            <div class="col-lg-2">
                                                <label for="defaultconfig" class="col-form-label"
                                                    style="padding-left: 15px">Hasil Pencarian</label>
                                            </div>

                                            <div class="col-lg-10" v-if="total == 0">
                                                <h5>No data was found for the intended search, you go straight
                                                    register with a click <a href="../driver/add">DISINI</a></h5>


                                            </div>

                                            <div class="col-lg-10" v-if="total > 0">
                                                <h5 style="color:red;">{{ total }} data found
                                                </h5>


                                            </div>

                                        </div>




                                        <div class="card-footer">
                                            <button class="btn btn-warning text-black fw-bolder btn-icon-text"
                                                @click="backForm" type="button">
                                                <i class="link-icon" data-feather="arrow-left"></i>
                                                {{ $t("backMess") }}
                                            </button>

                                            &nbsp;&nbsp;&nbsp;
                                            <button v-if="!isLoading" class="btn btn-primary float-end btn-sm"
                                                type="submit">
                                                <i class="link-icon" data-feather="search"></i>
                                                Check
                                            </button>

                                            <div v-if="isLoading" class="d-flex align-items-center">
                                                <div class="spinner-border ms-auto" role="status" aria-hidden="true">
                                                </div>
                                                <strong>{{ $t("loadingTxt") }}...</strong>
                                            </div>
                                        </div>

                                        <div class="row mb-3" v-show="result && totalRecords != 0">
                                            <vue-good-table title="list-user" mode="remote"
                                                @on-page-change="onPageChange" @on-sort-change="onSortChange"
                                                @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
                                                :totalRows="totalRecords" :line-numbers="true"
                                                :isLoading.sync="isLoading" :pagination-options="{
                                        enabled: true,
                                    }" :rows="fetchDataDriverBlacklist" :columns="columns">
                                                <template slot="table-row" slot-scope="props">

                                                    <span v-if="props.column.field == 'actions'">



                                                        <a href="#" v-if="props.row.category.toLowerCase() !== 'high'"
                                                            title="sendApproval" @click.prevent="
                                        sendApproval(props.index, props.row)
                                        ">
                                                            <i class="mdi mdi-send" style="font-size:16px"></i>

                                                        </a>




                                                    </span>

                                                </template>
                                            </vue-good-table>
                                        </div>












                                    </form>
                                </div>
                            </div>
                            <!--end::card-->
                        </div>
                    </div>






                </div>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
    </div>
</template>

<script>


import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import moment from "moment";

export default {
    name: "DriverBlackListAdd",
    props: {},
    components: {

        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
    },
    data() {
        return {
            // fetchCity: [],
            // city: "",
            totalRecords: 0,
            serverParams: {
                columnFilters: {},
                sort: {
                    field: "",
                    type: "",
                },
                page: 1,
                per_page: 10,
            },
            columns: [
                {
                    label: "Type",
                    field: "type_list",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },
                },
                {
                    label: "NMK",
                    field: "driver_id",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },
                },

                {
                    label: "Driver Name",
                    field: "driver_name",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },
                },
                {
                    label: "ID Card",
                    field: "id_card_number",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },

                {
                    label: "SIM",
                    field: "sim_no",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },


                {
                    label: "Birth Place",
                    field: "birth_place",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },


                {
                    label: "Birth Date",
                    field: "birth_date",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },


                {
                    label: "Remarks",
                    field: "remarks",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },


                {
                    label: "Date Reported",
                    field: "date_reported",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },


                {
                    label: "Reported",
                    field: "pelapor",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },


                {
                    label: "Category",
                    field: "category",
                    filterOptions: {
                        enabled: false, // enable filter for this column

                    },

                },




                {
                    label: "Action",
                    field: "actions",
                    sortable: false,
                },
            ],
            result: false,
            fetchDriver: [],
            fetchDataDriverBlacklist: [],
            fetchCategory: [
                {
                    name: 'Hight'
                },
                {
                    name: 'Medium'
                },
                {
                    name: 'Low'
                }
            ],
            total: 0,
            category: "",
            driver: "",
            permision_role: [],
            isLoading: false,
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            errors: [],
            type_list: "INTERNAL",
            userData: "",
            companyCode: "",
            fetchCompany: [],
            fetchCompanyNotLinc: [],
            forms: {
                sim_no: "",
                driver_name: "",
                id_card_number: '',
            },
            company: [],
        };
    },
    watch: {},
    methods: {
        resetForm() {
            this.forms = {
                id_card_number: "",
                driver_name: "",
                sim_no: "",
            };
            this.total = undefined;
            this.result = false;
        },

        loadCompany() {
            const baseURI = this.$settings.endPoint + "company?limit=10";

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;
            });
        },

        sendApproval(index, row) {
            this.$swal({
                title: this.$t("areYouSure"),
                text: 'Are you sure to send driver approval?',
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
            }).then((result) => {
                var formData = {
                    driver_category: row.category,
                    driver_ktp: row.id_card_number,
                    driver_name: row.driver_name,
                    driver_sim: row.sim_no,
                    driver_type: row.type_list,
                    master_driver_id: row.master_driver_id != null ? row.master_driver_id : null,
                    driver_blacklist_id: row.driver_blacklist_id
                };

                if (result.isConfirmed) {
                    const baseURI = this.$settings.endPointCt + "driver-approval/create/";
                    this.loading(true); // Mulai loading

                    this.$http.post(baseURI, formData)
                        .then((response) => {
                            this.loading(false); // Selesai loading
                            if (response.data.status === 200) {

                                window.location.href = "/driver/driver-approval?status=200&msg=Successfully";
                            } else {
                                this.errors = response.data.errors;
                                this.resultError(response.data.errors);
                            }
                        })
                        .catch((error) => {
                            this.loading(false); // Selesai loading
                            if (error.response) {
                                if (error.response.status === 422) {
                                    this.errors = error.response.data.errors;
                                    this.resultError(error.response.data.errors);
                                } else if (error.response.status === 500) {
                                    this.$router.push("/server-error");
                                } else {
                                    this.$router.push("/page-not-found");
                                }
                            }
                        });
                }
            });
        },


        loadCompanyNotLinc() {
            const baseURI = this.$settings.endPoint + `company/select`;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },

        asyncSearchCompany(ev) {
            const baseURI = this.$settings.endPoint + "company?code=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;
            });
        },

        asyncSearchCompanyNotLinc(ev) {
            const baseURI = this.$settings.endPoint + "company/select/code=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },




        // loadCity() {

        //     const baseURI =
        //         this.$settings.endPoint + `postal-code`;
        //     return this.$http.get(baseURI).then((response) => {
        //         this.fetchCity = response.data.datas.data;
        //     });
        // },

        // searchCity(ev) {
        //     const baseURI =
        //         this.$settings.endPoint + `postal-code?city=` + ev;
        //     return this.$http.get(baseURI).then((response) => {
        //         this.fetchCity = response.data.datas.data;
        //     });
        // },

        changeRadio() {
            console.log(this.forms.type_list);
        },

        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            this.companyCode = this.detailUser.company_detail.code;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == "DriverApprovalController") {
                    rolePermision = permision;
                    // console.log(permision);
                }
            });

            this.permision_role = rolePermision;
        },

        customLabel(ev) {
            return `${ev.driver_id} – ${ev.driver_name}`
        },

        backForm() {
            window.location.href = "/driver/driver-approval";
        },

        loadDriver() {
            const baseURI =
                this.$settings.endPointCt + `master-driver/select`;
            return this.$http.get(baseURI).then((response) => {
                this.fetchDriver = response.data.datas.data;

            });
        },

        asyncSearchDriver(ev) {
            this.fetchDriver = [];
            const baseURI =
                this.$settings.endPointCt + `master-driver/select?driver_id=` + ev;
            return this.$http.get(baseURI).then((response) => {
                this.fetchDriver = response.data.datas.data;
            });
        },


        loadItems() {
            this.result = false;
            const baseURI = this.$settings.endPointCt + "driver-blacklist";

            //var columnFilters = this.serverParams.columnFilters;


            return this.$http
                .get(
                    baseURI +
                    //  `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&driver_id=${this.serverParams.columnFilters.driver_id}&driver_name=${this.serverParams.columnFilters.driver_name}&driver_status=${this.serverParams.columnFilters.driver_status}&id_card_number=${this.serverParams.columnFilters.id_card_number}&nickname=${this.serverParams.columnFilters.nickname}&sim1_type=${this.serverParams.columnFilters.sim1_type}&sim1_expire_date=${this.serverParams.columnFilters.sim1_expire_date}&branch_name=${this.serverParams.columnFilters.branch_name}&project_name=${this.serverParams.columnFilters.project_name}`,
                    `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&driver_name=${this.forms.driver_name}&id_card_number=${this.forms.id_card_number}&sim_no=${this.forms.sim_no}`,
                )
                .then((response) => {
                    this.result = true;
                    this.fetchDataDriverBlacklist = response.data.datas.data
                    this.total = response.data.datas.total;
                    this.totalRecords = response.data.datas.total;
                });
        },




        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },


        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.loadItems();
        },

        onColumnFilter(params) {
            params.page = 1;
            this.updateParams(params);
        },

        onPageChange(params) {
            console.log(params);
            this.updateParams({ page: params.currentPage });
        },

        onSortChange(params) {
            this.updateParams({
                sort: {
                    type: params[0].type,
                    field: params[0].field,
                },
            });
        },

        onPerPageChange(params) {
            this.updateParams({ per_page: params.currentPerPage });
        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        // this.loadCity();


        // this.loadCompany();
        // this.loadCompanyNotLinc();
    },
};
</script>
<style scoped></style>