<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="Role"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    {{ $t("roleAdd") }}
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("roleNameTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          :placeholder="$t('roleNameTxt')"
                          v-model="forms.name"
                          required
                        />
                        <div v-if="errors.name">
                          <div
                            v-for="error in errors.name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("descriptionTxt") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="description"
                          :placeholder="$t('descriptionTxt')"
                          v-model="forms.description"
                        />
                        <div v-if="errors.description">
                          <div
                            v-for="error in errors.description"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("branchTxt") }}
                        </label>
                      </div>
                      <div class="col-lg-8">
                        <multiselect
                          v-model="branch"
                          :options="fetchBranch"
                          :multiple="true"
                          group-values="branch"
                          group-label="category"
                          :group-select="true"
                          placeholder="Type to search branch"
                          track-by="branch_name"
                          label="branch_name"
                          ><span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          ></multiselect
                        >
                                
                              
                             

                                 

                        <div v-if="errors.branch">
                          <div
                            v-for="error in errors.branch"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Truck Type
                        </label>
                      </div>
                      <div class="col-lg-8">
                        <multiselect
                          v-model="truck_type"
                          :options="fetchTruckType"
                          :multiple="true"
                          group-values="type_truck"
                          group-label="category"
                          :group-select="true"
                          placeholder="Type to search "
                          track-by="type_truck"
                          label="type_truck"
                          ><span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          ></multiselect
                        >

                        <div v-if="errors.truck_type">
                          <div
                            v-for="error in errors.truck_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Truck
                        </label>
                      </div>
                      <div class="col-lg-8">

                               

                              

                        <multiselect
                          v-model="truck"
                          :options="fetchTruck"
                          :multiple="true"
                          group-values="truck"
                          group-label="category"
                          :group-select="true"
                          placeholder="Type to search "
                          track-by="vehicle_no"
                          label="vehicle_no"
                          ><span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          ></multiselect
                        >


                        

                        <div v-if="errors.truck">
                          <div
                            v-for="error in errors.truck"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Permission </label>
                      </div>
                      <div class="col-lg-8">
                      
                        <v-select
                          :options="fetchPermission"
                          v-model="permission"
                          label="controller"
                          return-object
                          @search="asyncSearchPermission"
                          multiple 
                        ></v-select>

                        <div v-if="errors.permission">
                          <div
                            v-for="error in errors.permision"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="row card">
                      <div class="card-header">
                        <h5 class="float-start">
                          Permission <span class="wajib">*</span>
                        </h5>

                        <button
                          class="
                            btn btn-success btn-icon
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          "
                          @click="addField()"
                          type="button"
                        >
                          <i class="link-icon" data-feather="plus"></i>
                        </button>
                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table
                            class="table"
                            style="height: 300px"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th style="width:500px">Permission</th>
                                <th>Create</th>
                                <th>Read</th>
                                <th>Update</th>
                                <th>Delete</th>
                                <th>Upload</th>
                                <th>custom1</th>
                                <th>custom2</th>
                                <th>custom3</th>
                                <th>custom4</th>
                                <th>custom5</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              <tr
                                v-for="(input, index) in this.detailAll"
                                :key="`phoneInput-${index}`"
                              >
                                <th>{{ index + 1 }}</th>
                                <td style="width:500px">
                                
                                  <multiselect @search-change="asyncSearchPermission" v-model="input.permission" :options="fetchPermission" placeholede="Please Select Permission"  label="controller" track-by="controller"></multiselect>
                                  <!-- <v-select
                                  :options="fetchPermission"
                                  v-model="input.permision"
                                  label="controller"
                                  return-object
                                  @search="asyncSearchPermission"
                                ></v-select> -->

                                <!-- <v-select
                                  :filter="asyncSearchPermission"
                                  :options="fetchPermission"
                                  :get-option-label="(option) => option.controller"
                                >
                                  <template #option="{ controller}">
                                    {{ controller }} asdsad
                                  
                                  </template>
                                </v-select> -->

                                </td>

                                <td width="200px">
                                  
                                  <select class="form-control" v-model="input.method_create">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                  </select>
                              </td>


                              <td width="200px">
                                  <select class="form-control" v-model="input.method_read">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                  </select>
                                
                              </td>


                              <td width="200px">
                                <select class="form-control" v-model="input.method_update">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                               
                              </td>

                               <td width="200px">
                                <select class="form-control" v-model="input.method_delete">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                                
                              </td>


                              <td width="200px">
                                <select class="form-control" v-model="input.method_upload">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                                
                              </td>


                              <td width="200px">
                                <select class="form-control" v-model="input.method_custom1">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                               
                              </td>

                              <td width="200px">
                                <select class="form-control" v-model="input.method_custom2">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                               
                              </td>


                              <td width="200px">
                                <select class="form-control" v-model="input.method_custom3">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                               
                              </td>

                              <td width="200px">
                                <select class="form-control" v-model="input.method_custom4">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                                
                              </td>


                              <td width="200px">
                                <select class="form-control" v-model="input.method_custom5">
                                      <option value="1" >Yes</option>
                                      <option value="0" >No</option>
                                </select>
                                 
                              </td>
                                <td>
                                  <!--          Remove Svg Icon-->
                                  <svg
                                    @click="removeField(index, detailAll)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    class="ml-2 cursor-pointer"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                      fill="red"
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                    />
                                  </svg>

                                   <a
                                    href="#"
                                    title="Check All"
                                    v-if="!(input.method_read == 1 && input.method_create == 1 && input.method_update == 1  && input.method_upload == 1  && input.method_delete == 1  && input.method_custom1 == 1  && input.method_custom2 == 1  && input.method_custom3 == 1  && input.method_custom4 == 1  && input.method_custom5 == 1 )"
                                    @click.prevent="
                                      checkAll(index, detailAll)
                                    "
                                  >
                                      <i class="mdi mdi-check-circle-outline" style="font-size:24px"></i> 
                                  </a>

                                    <a
                                    href="#"
                                    title="unCheck All"
                                    v-else
                                    @click.prevent="
                                      unCheckAll(index, detailAll)
                                    "
                                  >
                                      <i class="mdi mdi-circle-off-outline" style="font-size:24px"></i> 
                                  </a>
                                </td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

export default {
  name: "RoleAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      fetchBranch: [],
      fetchTruckType: [],
      fetchTruck: [],
      branch: [],
      branchFilter: [],
      truck_type: [],
      truck: [],
      permision_role: [],
      fetchPermission: [],
      fetchOptionSelect: [
        {
          id: 1,
          name: "Yes",
        },
        {
          id: 0,
          name: "No",
        },
      ],
      permission: [],
      userData: "",
      companyCode: "",
      forms: { name: "", usernmae: "" },
      detailAll: [],
    };
  },
  watch: {},
  methods: {
    resetForm() {
      this.forms.name = "";
      this.forms.description = "";
    },

    backForm() {
      window.location.href = "/role";
    },
    checkAll(index,detail){
      detail[index].method_create = 1;
      detail[index].method_read = 1;
      detail[index].method_update = 1;
      detail[index].method_delete = 1;
      detail[index].method_upload = 1;
      detail[index].method_custom1 = 1;
      detail[index].method_custom2 = 1;
      detail[index].method_custom3 = 1;
      detail[index].method_custom4 = 1;
      detail[index].method_custom5 = 1;
    },
     unCheckAll(index,detail){
      detail[index].method_create = 0;
      detail[index].method_read = 0;
      detail[index].method_update = 0;
      detail[index].method_delete = 0;
      detail[index].method_upload = 0;
      detail[index].method_custom1 = 0;
      detail[index].method_custom2 = 0;
      detail[index].method_custom3 = 0;
      detail[index].method_custom4 = 0;
      detail[index].method_custom5 = 0;
    },
    addField() {
      //fieldType.push({ value: "" });
      this.detailAll.push({
        permission: [],
        method_create: "",
        method_read: "",
        method_update: "",
        method_delete: "",
        method_upload: "",
        method_custom1: "",
        method_custom2: "",
        method_custom3: "",
        method_custom4: "",
        method_custom5: "",
      });
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    submitData() {
      this.fade(true);

      let sendBranch = [];
      let sendTruck = [];
      let sendTruckType = [];
      let sendPermission = [];

      this.branch.forEach((item) => {
        const data = {
          branch_id: item.branch_id,
        };
        sendBranch.push(data);
      });

      this.truck.forEach((item) => {
        const data = {
          master_truck_id: item.master_truck_id,
        };
        sendTruck.push(data);
      });

      this.truck_type.forEach((item) => {
        const data = {
          type_truck_id: item.type_truck_id,
        };
        sendTruckType.push(data);
      });

      // console.log(this.detailAll);
      this.detailAll.forEach((item) => {
        
        const data = {
          permission_id: item.permission.permission_id,
          method_create: item.method_create,
          method_read: item.method_read,
          method_update: item.method_update,
          method_delete: item.method_delete,
          method_upload: item.method_upload,
          method_custom1: item.method_custom1,
          method_custom2: item.method_custom2,
          method_custom3: item.method_custom3,
          method_custom4: item.method_custom4,
          method_custom5: item.method_custom5,
        };
        sendPermission.push(data);
      });

      this.forms.role_branch_permission = sendBranch;
      this.forms.role_truck_permission = sendTruck;
      this.forms.role_type_truck_permission = sendTruckType;
      this.forms.permission_role = sendPermission;
      // console.log(this.forms);
      const baseURI = this.$settings.endPoint + "role/create";
      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            this.errors = [];

            var params = this.$onRandom(response.data.datas.role_id);
            window.location.href ="/role/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    asyncSearchBranch(ev) {
      const baseURI = this.$settings.endPointCt + `branch?branch_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        response.data.datas.data.forEach((item) => {
          const data = {
            branch_id: item.branch_id,
            branch_name: item.branch_name,
          };
          this.fetchBranch.push(data);
        });
      });
    },

    asyncSearchTruckType(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;

      return this.$http.get(baseURI).then((response) => {
        response.data.datas.data.forEach((item) => {
          const data = {
            type_truck_id: item.type_truck_id,
            type_truck: item.type_truck,
          };
          this.fetchTruckType.push(data);
        });
      });
    },

    loadTruckType() {
      const baseURI = this.$settings.endPointCt + `type-truck?select=select`;
      return this.$http.get(baseURI).then((response) => {
        let data = {
          category: " -- Select All Type Truck --",
          type_truck: response.data.datas.data,
        };
        this.fetchTruckType.push(data);
      });
    },

    loadTruck() {
      const baseURI = this.$settings.endPointCt + `master-truck?select=select`;

      return this.$http.get(baseURI).then((response) => {
        let data = {
          category: " -- Select All Truck --",
          truck: response.data.datas.data,
        };

        this.fetchTruck.push(data);
      });
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch?select`;

      return this.$http.get(baseURI).then((response) => {
        let data = {
          category: " -- Select All Branch --",
          branch: response.data.datas.data,
        };
        this.fetchBranch.push(data);
        //console.log(this.fetchBranch);
      });
    },

    asyncSearchTruck(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-truck?vehicle_no=` + ev;

      return this.$http.get(baseURI).then((response) => {
        response.data.datas.data.forEach((item) => {
          const data = {
            master_truck_id: item.master_truck_id,
            vehicle_no: item.vehicle_no,
          };
          this.fetchTruck.push(data);
        });
      });
    },

    loadPermission() {
      const baseURI = this.$settings.endPoint + `permission`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPermission = response.data.datas.data;
      });
    },

    asyncSearchPermission(ev) {
      const baseURI = this.$settings.endPoint + `permission?controller=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPermission = response.data.datas.data;
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RoleController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    onUnselectBranch(ev) {
      this.branchFilter.splice(this.branchFilter.indexOf(ev.branch_id), 1);
      //this.loadLocationTable();
    },

    onSelectBranch(ev) {
      this.branchFilter.push(ev.branch_id);
      //console.log(this.branchFilter);
      //this.loadLocationTable();
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllTypeTruck) {
          this.type_truck = [];
        } else {
          this.type_truck = this.fetchTruckType.slice();
        }
      });
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    loadDetail(id) {
      const baseURI = this.$settings.endPoint + "role/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        
        let datas = response.data.datas;

       
           datas.permission_role.forEach((item) => {
            
            const data = {
                permission: item.permission,
                method_create:item.method_create,
                method_read:item.method_read,
                method_update:item.method_update,
                method_delete:item.method_delete,
                method_upload:item.method_upload,
                method_custom1:item.method_custom1,
                method_custom2:item.method_custom2,
                method_custom3:item.method_custom3,
                method_custom4:item.method_custom4,
                method_custom5:item.method_custom5,
            };
            //console.log(data);
            this.detailAll.push(data);
          });


         
           
          //let detailBranch=[];
          datas.role_branch_permission.forEach((item) => {
            const data = {
              branch_id: item.branch.branch_id,
              branch_name: item.branch.branch_name,
            
            };
            this.branch.push(data);

            
          });
          
          datas.role_truck_permission.forEach((item) => {
            const data = {
              master_truck_id: item.master_truck.master_truck_id,
              vehicle_no: item.master_truck.vehicle_no,
            
            };
            this.truck.push(data);
          });


          datas.role_type_truck_permission.forEach((item) => {
            const data = {
              type_truck_id: item.type_truck.type_truck_id,
              type_truck: item.type_truck.type_truck,
            
            };
            this.truck_type.push(data);
          });


      });
    },

  },
  events: {},
  computed: {
    likesAllTypeTruck() {
      return this.truck_type.length === this.fetchTruckType.length;
    },
    likesSomeTypeTruck() {
      return this.truck_type.length > 0 && !this.likesAllTypeTruck;
    },
    icon() {
      if (this.likesAllTypeTruck) return "mdi-close-box";
      if (this.likesSomeTypeTruck) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  created: function () {},
  mounted() {
    this.fetchIt();
    this.loadTruckType();
    this.loadTruck();
    this.loadBranch();
    this.loadPermission();

     if (this.$route.params.id && this.$route.params.id !== "") {
      const params = this.$onBehind(this.$route.params.id);
      this.loadDetail(params[0]);
    }
  },
};
</script>
<style scoped>
</style>