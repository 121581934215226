<template>


  <div>
    <div class="loader" v-if="this.isLoading_dashboard"></div>
    <div class="loadertext" v-if="this.isLoading_dashboard">{{ $t("loading") }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading_dashboard }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="DashboardCT"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
              <h4 class="mb-3 mb-md-0">{{ $t("DashboardMonitoringTxt") }}</h4>
            </div>
            <div class="d-flex align-items-center flex-wrap text-nowrap">

              <button type="button" v-if="isMaps" class="btn btn-primary wd-200 me-2 mb-2 mb-md-0 col-xs-12"
                @click="btnMaps(false)">
                Maps: {{ $t("OffTxt") }}
              </button>
              <button type="button" v-if="!isMaps" class="btn btn-success wd-200 me-2 mb-2 mb-md-0 col-xs-12"
                @click="btnMaps(true)">
                Maps: {{ $t("OnTxt") }}
              </button>


              <button type="button" class="btn btn-success btn-icon-text mb-2 mb-md-0 col-xs-12" @click="downloadData">
                <i class="btn-icon-prepend" data-feather="download-cloud"></i>
                {{ $t("downloadData") }}
              </button>
            </div>
          </div>

          <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="card">
              <div class="card-body">


                <div class="row mb-3">

                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect v-model="billable" :options="fetchBillable" :multiple="true" :close-on-select="true"
                        :clear-on-select="true" :preserve-search="true" placeholder="Pick some Billable" label="name"
                        track-by="name" :preselect-first="true" @select="onSelectBillable" @remove="onUnselectBillable">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen">{{ values.length }} selected</span></template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect v-model="branch" :options="fetchBranch" :multiple="true" :close-on-select="true"
                        :clear-on-select="true" :preserve-search="true" placeholder="Pick some Branch"
                        label="branch_name" track-by="branch_name" :preselect-first="true" @select="onSelectBranch"
                        @remove="onUnselectBranch">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Branch
                            selected</span></template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect v-model="truck" :options="fetchTruck" :multiple="true" :close-on-select="false"
                        :clear-on-select="false" :preserve-search="true" placeholder="Pick some Type Truck"
                        label="type_truck" track-by="type_truck" :preselect-first="true" @select="onSelectTypeTruck"
                        @remove="onUnselectTypeTruck" @search-change="onSearchChange">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Truck selected</span>
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">
                      <multiselect v-model="status" :options="fetchStatus" :multiple="true" :close-on-select="false"
                        :clear-on-select="false" :preserve-search="true" placeholder="Pick some Status"
                        label="status_name" track-by="status_name" :preselect-first="true" @select="onSelectStatus"
                        @remove="onUnselectStatus">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen">{{ values.length }} Status
                            selected</span></template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    <div class="input-group">

                      <!-- <multiselect
                          v-model="vehicle_no"
                          :options="fetchTruckVehicleNo"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Vehicle No"
                          label="vehicle_no"
                          track-by="vehicle_no"
                          :preselect-first="true"
                          @select="onSelectVehicleNo"
                          @remove="onUnselectVehicleNo"
                          @search-change="onSearchChangeTruckVehicleNo"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} selected</span
                            >
                          </template>
                        </multiselect> -->
                      <textarea class="form-control" :rows="rowsTextarea" placeholder="Vehicle No" v-model="vehicle_no"
                        @change="selectVehicleNo"></textarea>
                    </div>
                  </div>

                  <div class="col-lg-1 col-md-12 col-sm-12 col-xs-12">
                    <button class="btn btn-primary float-end" @click="resetFilterLocation">
                      Reset Filter
                    </button>
                  </div>

                </div>

                <div class="row" v-if="isMaps">
                  <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 500px">
                    <gmap-marker :key="index" v-for="(gmp, index) in locations" :position="gmp" :icon="gmp.icon"
                      :clickable="true" @click="openMarker(gmp)">
                      <gmap-info-window :opened="true">
                        <span class="badge" :style="{
      'background-color': gmp.color,
      color: 'white',
    }" :opened="false">
                          {{ gmp.label }}
                        </span>
                        <!-- <span :class="gmp.color" style="margin:20px"> {{ gmp.label }} </span> -->
                      </gmap-info-window>
                    </gmap-marker>

                    <!-- <DirectionsRenderer
                        travelMode="DRIVING"
                        :origin="startLocation"
                        :destination="endLocation"
                      /> -->

                    <!-- <gmap-info-window :position="{ lat:-6.1850691, lng: 106.795189 }">
                        Hello world!
                      </gmap-info-window> -->
                  </gmap-map>
                </div>

                <div class="row">
                  <p class="text-muted mb-3"></p>

                  <vue-good-table title="list-dashboard" mode="remote" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                    :isLoading_dashboard.sync="isLoading_dashboard" :pagination-options="{
      enabled: false,
    }" :rows="rowsTable" :columns="columns">
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'vehicle_no'">
                        <span @click="searchVehicleNo(props.row)" :style="{
      'background-color': props.row.color,
      cursor: 'pointer',
    }" class="badge">{{ props.formattedRow[props.column.field] }}
                        </span>
                        <!-- <i   
                            class="mdi mdi-alert"
                            v-if="props.row.service.length > 0 && props.row.trip.length > 0 && props.row.doc.length > 0"
                            style="font-size: 16px; color: red"
                            @click="mainAlertClick(props.row)"
                          ></i> -->

                        <i class="mdi mdi-alert" v-if="props.row.service > 0 || props.row.trip > 0 || props.row.doc > 0"
                          style="font-size: 16px; color: red" @click="mainAlertClick(props.row)"></i>

                      </span>
                      <span v-else-if="props.column.field == 'driver_name'">
                        {{ props.row.driver_name }}
                        {{ props.row.driver_hp }}
                      </span>



                      <span v-else-if="props.column.field == 'stnk'">
                        <span style="background-color: red" class="badge" v-if="currentDate > props.row.stnk">{{
      props.row.stnk }}</span>
                        <span style="color: black" class="badge" v-else>{{
      props.row.stnk
    }}</span>
                      </span>

                      <span v-else-if="props.column.field == 'engine_car'">
                        <span style="background-color: red" class="badge" v-if="props.row.engine_car == 'OFF'">{{
      props.row.engine_car }}</span>
                        <span style="background-color: green" class="badge" v-else>{{ props.row.engine_car }}</span>
                      </span>

                      <span v-else-if="props.column.field == 'kir'">
                        <span style="background-color: red" class="badge" v-if="currentDate > props.row.kir">{{
      props.row.kir }}</span>
                        <span style="color: black" class="badge" v-else>{{
      props.row.kir
    }}</span>
                      </span>

                      <span v-else-if="props.column.field == 'detail'">
                        <a href="#" title="Detail" @click.prevent="detailData(props.index, props.row)">
                            <i class="mdi mdi-eye" style="font-size: 16px"></i>
                        </a>

                      </span>

                      <span v-else-if="props.column.field == 'gps_vendor'">
                        {{ props.row.gps_vendor.substring(0, 3) }}
                      </span>


                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>

    <modal-main-alert :showModal="showModalNow" :data="detailMainAlert" :dataBasicInfo="detailBlujay"
      :dataOrderBlujay="detailOrderBlujay" @closeModal="closeMyModal"></modal-main-alert>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
import modalMainAlert from "@/components/modal/modalMainAlert";

// import DirectionsRenderer from "@/middleware/DirectionsRenderer";
import moment from "moment";

export default {
  name: "DashboardCT",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-main-alert": modalMainAlert,
    multiselect: multiselect,

    // DirectionsRenderer,
  },
  data() {
    const mapMarkerIcon = require("../../assets/icontruck.png");
    return {
      rowsTextarea: 1,
      showModalNow: false,
      zoom: 5,
      isLoading_dashboard: false,
      totalRecords: 0,
      openedMarkerID: true,
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },
      company_code: "",
      branch: [],
      currentDate: moment(String(new Date())).format("YYYY-MM-DD"),
      truck: [],
      status: [],
      vehicle_no: [],
      billable: [],
      fetchBranch: [],
      fetchBillable: [],
      fetchTruck: [],
      fetchStatus: [],
      options: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isMaps: false,
      errors: [],
      featchTruck: [],
      featchStatus: [],
      featchBranch: [],
      listWithOnly3Item: "",
      langs: ["id", "en"],
      name: "",
      startLocation: null,
      endLocation: null,
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      forms: [],
      locationMarkers: [],
      locations: [],
      locPlaces: [],
      realMonitoring: [],
      branchFilter: [],
      type_truckFilter: [],
      vehicle_no_Filter: [],
      statusFilter: [],
      searchVhicle: "",
      rowsTable: [],
      billableFilter: [],
      fetchTruckVehicleNo: [],
      columns: [
        {
          label: "Last Updated",
          field: "time_server",
        },

        {
          label: "Status",
          field: "truck_status",
        },

        {
          label: "Vehicle No",
          field: "vehicle_no",
        },

        {
          label: "Branch",
          field: "branch_name",
        },

        {
          label: "Own",
          field: "ownership",
        },

        {
          label: "Carrier",
          field: "carrier",
        },

        {
          label: "Type",
          field: "type_truck",
        },

        {
          label: "Driver Name",
          field: "driver_name",
        },

        // {
        //   label: "Driver Hp",
        //   field: "blujay_order.driver_hp",
        // },

        {
          label: "GPS",
          field: "gps_vendor",
        },

        {
          label: "Billable",
          field: "billable",
        },

        {
          label: "Last Location",
          field: "location",
        },

        // {
        //   label: "City",
        //   field: "city",
        // },

        // {
        //   label: "Province",
        //   field: "province",
        // },

        // {
        //   label: "Region",
        //   field: "region",
        // },

        /*{
          label: "Temp",
          field: "temperature",
        },*/

        {
          label: "Speed",
          field: "speed",
        },

        {
          label: "ENG",
          field: "engine_car",
        },

        {
          label: "KM",
          field: "mileage",
        },

        {
          label: "KIR",
          field: "kir",
        },

        {
          label: "STNK",
          field: "stnk",
        },

        {
          field: "detail",
          sortable: false,
        },
      ],
      detailMainAlert: "",
      detailBlujay: "",
      detailOrderBlujay: [],
      rows: [],
      load_id: "",
      equipment: "",
      load_group: "",
      driver_name: "",
      driver_hp: "",
      date_time: "",
      address: "",
      orders: "",
      htmlTable: "",
      permissionTabsBasic: '',
      permissionTabsShipment: '',
      permissionTabsDriver: '',
      permision_role: '',
      deposit: 0,

    };
  },
  watch: {},
  methods: {
    btnMaps(stype) {
      this.isMaps = stype;
      //this.loadLocationTable();

    },



    // fade(sType) {
    //   this.isLoading_dashboard = sType;
    // },
    closeMyModal() {
      this.detailOrderBlujay = [];
      this.showModalNow = false;
    },


    openMarker(detail) {
      let sts_engine = "";
      if (detail.engine_car == "ON") {
        sts_engine = '<a style="color:green;">' + detail.engine_car + "<a>";
      } else {
        sts_engine = '<a style="color:red;">' + detail.engine_car + "<a>";
      }
      this.$swal({
        position: "top-end",
        title: false,
        icon: false,
        showCloseButton: true,
        showConfirmButton: false,
        header: '<a href=""></a>',
        html:
          '<ul class="nav nav-tabs" id="myTab" role="tablist">' +
          '<li class="nav-item" ' + this.permissionTabsBasic + '>' +
          '<a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basic</a>' +
          "</li>" +
          '<li class="nav-item" ' + this.permissionTabsShipment + '>' +
          '<a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" >Shipment</a>' +
          "</li>" +
          '<li class="nav-item" ' + this.permissionTabsDriver + '>' +
          '<a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Driver</a>' +
          "</li>" +
          '<li class="nav-item">' +
          '<span style="margin: 20px; color:">Eng:' +
          sts_engine +
          '<span > AC: <a style="color:red;">OFF</a> </span>' +
          "</li>" +
          "</ul>" +
          '<div class="tab-content border border-top-0 p-3" id="myTabContent">' +
          '<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">' +
          '<img src="' + detail.images + '" width="150px" height="150px"  />' +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Vehicle No</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.vehicle_no +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load ID</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.load_id +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Equipment</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.equipment +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Load Group</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.load_group +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Name</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_name +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Driver Hp</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.driver_hp +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Date Time</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.time_server +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Address</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.location +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Speed</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.speed +
          "</b></td>" +
          "</tr>" +
          "<tr>" +
          "<td style='text-align:left'>Temp</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          detail.temp +
          "</b></td>" +
          "</tr>" +
          "</table>" +
          "</div>" +
          '<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">' +
          detail.htmlTable +
          "</div>" +
          '<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">' +
          "<table>" +
          "<tr>" +
          "<td style='text-align:left' width='120px'>Deposit</td>" +
          "<td style='text-align:left'>:</td>" +
          "<td style='text-align:left'><b>" +
          this.formatNumber(detail.deposit) +
          "</b></td>" +
          "</tr>" +

          "</table>" +

          "</div>",
      });
    },

    initMarker(loc) {
      this.existingPlace = loc;
    },

    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });
      // this.locations = [
      //   {
      //     id:1,
      //     lat: -6.2066575,
      //     lng: 106.7759629,
      //     label: "B 123123 EG",
      //     rotate:20,
      //     color:"badge bg-primary"
      //   },
      //   {
      //     id:2,
      //     lat: -6.181783879012301,
      //     lng: 106.79797850513704,
      //     label: "B 22222 EH",
      //     rotate:40,
      //     color:"badge bg-success"
      //   },
      //   {
      //     id:3,
      //     lat: -6.1893281,
      //     lng: 106.764631,
      //     label: "B 12223 EJ",
      //     rotate:90,
      //     color:"badge bg-warning"
      //   },
      //   {
      //     id:4,
      //     lat: -6.1810517,
      //     lng: 106.7679362,
      //     label: "F 231312 EG",
      //     rotate:70,
      //     color:"badge bg-info"
      //   },
      // ];
    },

    formatNumber(value) {
      return parseInt(value).toLocaleString()
    },

    changeFilterLocation() {
      let dataSend = {
        branch: this.branchFilter,
        type_truck: this.type_truckFilter,
        status: this.statusFilter,
        vehicle_no: this.vehicle_no_Filter,
      };
      //this.realMonitoring =[];
      this.locations = [];
      this.rowsTable = [];

      this.loadFilter(dataSend);
    },

    // showModalAlert() {
    //   this.$modal.show("my-main-alert-modal");
    // },

    // hideModalAlert() {
    //   this.$modal.hide("my-main-alert-modal");
    // },

    loadFilter(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring`;
      return this.$http.post(baseURI, data).then((response) => {
        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        this.rows = response.data.datas.data;
        this.loadFetchLocation();
        this.loadFetchLocationTable();
      });
    },

    mainAlertClick(data) {
      this.detailMainAlert = data;
      this.loadMainAlert(data.vehicle_no);
      const baseURI =
        this.$settings.endPointCt + `blujay-order/detail-active/` + data.vehicle_no;

      return this.$http.get(baseURI).then((response) => {
        this.detailBlujay = response.data.datas;
        this.showModalNow = !this.showModalNow;
        //this.detailBlujay = response.data.datas.data;
        //this.loadDataDetail(response.data.datas.blujay_order_id);

        //console.log(this.forms);
      }).catch(() => {

        this.showModalNow = true;
      })

    },

    loadMainAlert(vehicle_no) {
      const baseURI =
        this.$settings.endPointCt + `blujay-order/main-alert/` + vehicle_no;

      return this.$http.get(baseURI).then((response) => {

        response.data.datas.forEach(res => {
          let pecah = res.name_rute.split(",");
          res.order_detail_alert.forEach((res2) => {
            let dataA = {
              id: res.blujay_order_id,
              driver_id: res.driver_id,
              //driver_name:res.driver_name,
              customer_name: res2.customer_name,
              pickup: pecah[0] ? pecah[0].slice(8) : '',
              delivery: pecah[1] ? pecah[1].slice(8) : '',
              requested_delivery: res2.requested_delivery,
            }
            // console.log(this.detailOrderBlujay.length);
            if (this.detailOrderBlujay.length <= 2) {
              this.detailOrderBlujay.push(dataA);
            }




          });




        });
        // this.listWithOnly3Items = this.detailOrderBlujay.slice(0, 3)
        // console.log(listWithOnly3Items);
        //let datafilter=this.filterDataOrderBlujay();
        //console.log(datafilter);
        //this.detailBlujay = response.data.datas.data;
        //this.loadDataDetail(response.data.datas.blujay_order_id);

        //console.log(this.forms);
      }).catch(() => {

        this.detailOrderBlujay = [];
      })
    },

    // filterDataOrderBlujay () {
    //     return this.detailOrderBlujay && this.detailOrderBlujay.length > 0 && (this.detailOrderBlujay.length - 1) <= this.limit  // or any condition u want 
    // },

    loadFilterClick(data) {
      const baseURI = this.$settings.endPointCt + `real-monitoring`;
      return this.$http.post(baseURI, data).then((response) => {

        this.realMonitoring = response.data.datas.data;
        this.totalRecords = response.data.datas.total;
        //this.rows = response.data.datas.data;
        this.loadFetchLocation();

      });
    },

    //filter dashboard
    onUnselectBranch(ev) {
      this.branchFilter.splice(this.branchFilter.indexOf(ev.branch_id), 1);
      this.loadLocationTable();
    },

    onSelectBranch(ev) {
      this.branchFilter.push(ev.branch_id);

      this.loadLocationTable();
    },


    onSelectBillable(ev) {
      this.billableFilter.push(ev.name);
      this.loadLocationTable();
    },

    onUnselectBillable(ev) {
      this.billableFilter.splice(this.billableFilter.indexOf(ev.id), 1);
      this.loadLocationTable();
    },

    onSelectTypeTruck(ev) {
      this.type_truckFilter.push(ev.type_truck_id);
      this.loadLocationTable();
    },

    onUnselectTypeTruck(ev) {
      this.type_truckFilter.splice(this.type_truckFilter.indexOf(ev.type_truck_id), 1);
      this.loadLocationTable();
    },

    onSelectVehicleNo(ev) {
      this.vehicle_no_Filter.push(ev.vehicle_no);
      this.loadLocationTable();
    },

    selectVehicleNo() {
      this.rowsTextarea = 1;
      // console.log(this.vehicle_no.length);
      if (this.vehicle_no.length > 120) {
        this.rowsTextarea = 10;
      }
      this.vehicle_no_Filter = [];
      var pisah = this.vehicle_no.split(', ');
      //  console.log(pisah);
      this.vehicle_no_Filter.push(pisah);
      this.loadLocationTable();
    },

    onUnselectVehicleNo(ev) {
      this.vehicle_no_Filter.splice(this.vehicle_no_Filter.indexOf(ev.vehicle_no), 1);
      this.loadLocationTable();
    },

    onSelectStatus(ev) {
      this.statusFilter.push(ev.status_name);
      this.loadLocationTable();
    },

    onUnselectStatus(ev) {
      this.statusFilter.splice(this.statusFilter.indexOf(ev.status_name), 1);
      this.loadLocationTable();
    },
    //akhir filter dashboard
    detailData(index, row) {
      var params = this.$onRandom(row.id);

      window.location.href =
        "/monitoring/detail/" +
        params +
        "/" +
        row.vehicle_no +
        "/" +
        row.gps_vendor;
    },

    searchVehicleNo(ev) {
      this.locations = [];
      this.isMaps = true;
      this.zoom = 15;
      this.center = {
        lat: ev.lat,
        lng: ev.lng,
      },

        // let dataSend2 = {
        //   vehicle_no: ev.vehicle_no,
        // };
        this.realMonitoring = [];

      this.realMonitoring.push(ev);
      //console.log(this.realMonitoring);
      this.loadFetchLocation_single_select();
      // this.totalRecords = response.data.datas.total;
      //this.rows = response.data.datas.data;
      //this.loadFetchLocation();

      // this.loadFilterClick(dataSend2);
    },


    searchVehicleNoByText() {
      this.locations = [];
      let dataSend2 = {
        vehicle_no: this.searchVhicle,
      };
      this.loadFilter(dataSend2);
    },


    locateGeoLocation(latitude, longitude) {
      const marker = {
        lat: latitude,
        lng: longitude,
      };
      this.locationMarkers.push({ position: marker });
      this.locPlaces.push(this.existingPlace);
      this.center = marker;
      this.existingPlace = null;
    },

    // loading() {
    //   this.fade(true);
    //   setTimeout(() => {
    //     this.isLoading_dashboard = false;
    //   }, 1000); // hide the message after 3 seconds
    // },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.company_code = userDatas.sub.company_detail.code;
      // alert(this.company_code);
      // this.detailUser = userDatas.sub;
      // // this.permision_role = userDatas.sub.permision_role;
      // let rolePermision = [];
      // userDatas.sub.permision_role.forEach(function (permision) {
      //   if (permision.permission.controller == "PackageController") {
      //     rolePermision = permision;
      //     // console.log(permision);
      //   }
      // });
      // if (!rolePermision.method_read) {
      //   this.$router.push("/authorized-error");
      // }
      // this.permision_role = rolePermision;
    },

    loadBranch() {
      const baseURI = this.$settings.endPointCt + `branch/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadLocationTable();
    },

    onColumnFilter(params) {
      params.page = 1;
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    loadStatus() {
      const baseURI = this.$settings.endPointCt + `master-status?limit=100`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchStatus = response.data.datas.data;
      });
    },

    cek_permission_tabs() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'BlujayOrderController') {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      this.permision_role = rolePermision;

      if (!this.permision_role.method_custom1) {
        this.permissionTabsBasic = " hidden";
      }

      if (!this.permision_role.method_custom2) {
        this.permissionTabsShipment = " hidden";
      }


      if (!this.permision_role.method_custom3) {
        this.permissionTabsDriver = " hidden";
      }

    },

    loadTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck/select?limit=10`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },

    onSearchChange(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck/select?type_truck=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruck = response.data.datas.data;
      });
    },

    //vehicle no filter
    loadTruckVehicleNo() {
      const baseURI = this.$settings.endPointCt + `master-truck/select`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });
    },

    onSearchChangeTruckVehicleNo(ev) {
      const baseURI = this.$settings.endPointCt + `master-truck/select?vehicle_no=` + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckVehicleNo = response.data.datas.data;
      });

    },

    //akhir filter vehicle no

    resetFilterLocation() {
      this.searchVhicle = "";
      this.branch = "";
      this.truck = "";
      this.status = "";
      this.billable = "";
      this.billableFilter = "";
      this.branchFilter = [];
      this.type_truckFilter = [];
      this.statusFilter = [];
      this.vehicle_no_Filter = [];
      this.vehicle_no = "";
      this.loadLocationTable();
      this.rowsTextarea = 1;
      this.zoom = 5;
    },



    loadLocationTable() {
      this.isLoading_dashboard = true;
      this.rows = [];
      this.realMonitoring = [];
      this.locations = [];
      this.rowsTable = [];

      let branch = this.branchFilter.toString();

      let type_truck = this.type_truckFilter.toString();
      let status = this.statusFilter.toString();
      let vehicle_no = this.vehicle_no_Filter.toString();
      let billable = this.billableFilter.toString();



      const baseURI = this.$settings.endPointCt + `real-monitoring`;
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&billable=${billable}&download=all`
        )
        .then((response) => {
          this.isLoading_dashboard = false;
          this.rows = response.data.datas;
          this.realMonitoring = response.data.datas;
          this.totalRecords = 0;

          this.loadFetchLocationTable();
          this.loadFetchLocation();
          // navigator.geolocation.getCurrentPosition((geolocation) => {
          //   this.center = {
          //     lat: geolocation.coords.latitude,
          //     lng: geolocation.coords.longitude,
          //   };
          // });
          // let color="";

          // console.log(this.realMonitoring);
        }).catch(error => {
          this.isLoading_dashboard = false;
          //console.log(this.isLoading_dashboard);
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            this.isLoading_dashboard = false;
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },


    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },

    downloadData() {
      let branch = this.branchFilter.toString();

      let type_truck = this.type_truckFilter.toString();
      let status = this.statusFilter.toString();
      let vehicle_no = this.vehicle_no_Filter.toString();
      let billable = this.billableFilter.toString();

      const baseURI = this.$settings.endPointCt + `real-monitoring?branch=${branch}&status=${status}&type_truck=${type_truck}&vehicle_no=${vehicle_no}&billable=${billable}&download=download`;

      var file_name = `real_monitoring_.xlsx`;

      //var columnFilters = this.serverParams.columnFilters;
      return this.$http
        .get(
          baseURI,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          //this.fade(false);
        });
    },

    loadBlujay(blujayorder) {
      if (blujayorder !== null) {
        this.load_id = blujayorder.load_id;
        this.equipment = blujayorder.equipment;
        this.load_group = blujayorder.load_group;
        // this.driver_name = blujayorder.driver_name;
        // this.driver_hp = blujayorder.driver_hp;
        this.date_time = blujayorder.created_at;
        this.deposit = blujayorder.amount;
        // if(!blujayorder.amount){
        //   this.deposit = 0;
        // }
        this.orders = blujayorder.order_detail_alert;

        this.htmlTable = "";
        if (this.orders != null) {
          this.htmlTable += "<table>";
          this.orders.forEach((reso) => {

            let pecah = reso.name_rute.split(",");

            this.htmlTable +=
              "<tr>" +
              "<td style='text-align:left' width='120px'>Order Number</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" + reso.order_number + "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Customer</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" + reso.customer_name + "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Order Group</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" + reso.order_group + "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Status</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" + reso.tms_status + "</td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Target Suhu</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'></td>" +
              "</tr>" +
              "<tr>" +
              "<td style='text-align:left'>Rute</td>" +
              "<td style='text-align:left'>:</td>" +
              "<td style='text-align:left'>" + pecah[0]?.slice(8) + ' - ' + pecah[1]?.slice(8) + "</td>" +
              "</tr>" +
              "<tr>" +
              "<td colspan='3'>______________________________</td>" +
              "</tr>";
          });
          this.htmlTable += "</table>";
        }

      }
    },




    loadFetchLocation_single_select() {

      this.realMonitoring.forEach((res) => {
        this.locations = [];
        /*let stnk = "";
        let kir = "";
      

        res.master_document.forEach((resp) => {
          if (resp.type_document_id == 1) {
            stnk = resp.date_of_expire;
          }

          if (resp.type_document_id == 2) {
            stnk = resp.date_of_expire;
          }
        });*/



        //this.loadBlujay(res.blujay_order);

        this.locations.push({
          id: res.id,
          lat: res.lat,
          lng: res.lng,
          label: res.label,
          rotate: res.rotate,
          stnk: res.stnk,
          kir: res.kir,
          // master_document: res.master_document,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          speed: res.speed,
          billable: res.billable,
          engine_car: res.engine_car,
          mileage: res.mileage,
          color: res.color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server: res.time_server,
          blujay_order: res.blujay_order,
          service: res.service,
          trip: res.trip,
          doc: res.doc,
          last_km_service: res.last_km_service,
          last_service: res.last_service,
          next_pm_service: res.next_pm_service,
          load_id: res.load_id,
          equipment: res.equipment,
          load_group: res.load_group,
          driver_name: res.driver_name,
          driver_hp: res.driver_hp,
          date_time: res.date_time,
          address: res.address,
          orders: res.orders,
          temp: res.temp,
          icon: res.icon,
          deposit: res.deposit,
          images: res.images,
          htmlTable: res.htmlTable,
        });

      });


    },

    loadFetchLocation() {

      this.realMonitoring.forEach((res) => {
        // this.location = [];
        // let stnk = "";
        // let kir = "";
        let images = require("../../assets/no-image.png");

        /*res.master_document.forEach((resp) => {
          if (resp.type_document_id == 1) {
            stnk = resp.date_of_expire;
          }

          if (resp.type_document_id == 2) {
            stnk = resp.date_of_expire;
          }
        });*/


        if (res.images != null) {
          images = res.images;
        }

        this.load_id = "";
        this.equipment = "";
        this.load_group = "";
        this.driver_name = "";
        this.driver_hp = "";
        this.deposit = "";

        if (res.driver_last != null) {
          this.driver_name = res.driver_last.driver_name;
          this.driver_hp = res.driver_last.driver_hp;
          this.deposit = res.driver_last.amount;
        } else if (res.driver_batangan != null) {
          this.driver_name = res.driver_batangan.driver_name;
          this.driver_hp = res.driver_batangan.driver_hp;
          this.deposit = res.driver_batangan.amount;
        }

        this.date_time = "";
        this.address = "";
        this.orders = "";
        this.htmlTable = "";

        this.loadBlujay(res.blujay_order);


        this.locations.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          label: res.vehicle_no,
          rotate: 20,
          stnk: res.stnk,
          kir: res.kir,
          // master_document: res.master_document,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          speed: res.speed,
          billable: res.billable,
          engine_car: res.engine_car,
          mileage: res.mileage != null ? res.mileage.toLocaleString() : 0,
          color: res.status_color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server: res.time_server,
          blujay_order: res.blujay_order,
          service: res.service,
          trip: res.trip,
          doc: res.doc,
          last_km_service: res.last_km_service,
          last_service: res.last_service,
          next_pm_service: res.next_pm_service,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name,
          driver_hp: this.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          temp: "",
          deposit: this.deposit,
          ownership: res.ownership,
          htmlTable: this.htmlTable,
          images: images,
          icon: {
            path: 'M 0 0 C 6.625 -7.69 5.825 -2.318 10.132 0.035 L 4.627 -15.371',
            fillColor: res.status_color,
            fillOpacity: 1,
            rotation: res.heading,
            strokeWeight: 1,
            strokeColor: res.status_color,
            scale: 1.5,
          },
        });

      });


    },

    loadFetchLocationTable() {

      this.rowsTable = [];

      this.rows.forEach((res) => {
        // let stnk = "";
        // let kir = "";
        let images = require("../../assets/no-image.png");
        /*res.master_document.forEach((resp) => {
          if (resp.type_document_id == 1) {
            stnk = resp.date_of_expire;
          }

          if (resp.type_document_id == 2) {
            kir = resp.date_of_expire;
          }
        });*/

        this.load_id = "";
        this.equipment = "";
        this.load_group = "";
        this.driver_name = "";
        this.driver_hp = "";
        this.deposit = "";

        if (res.driver_last != null) {
          this.driver_name = res.driver_last.driver_name;
          this.driver_hp = res.driver_last.driver_hp;
          this.deposit = res.driver_last.amount;
        } else if (res.driver_batangan != null) {
          this.driver_name = res.driver_batangan.driver_name;
          this.driver_hp = res.driver_batangan.driver_hp;
          this.deposit = res.driver_batangan.amount;
        }

        this.date_time = "";
        this.address = "";
        this.orders = "";
        this.htmlTable = "";

        this.loadBlujay(res.blujay_order);
        if (res.images != null) {
          images = res.images;
        }


        this.rowsTable.push({
          id: res.real_monitoring_id,
          lat: res.latitude,
          lng: res.longitude,
          label: res.vehicle_no,
          rotate: 20,
          stnk: res.stnk,
          kir: res.kir,
          driver_last: res.driver_last,
          driver_batangan: res.driver_batangan,
          // master_document: res.master_document,
          location: res.location,
          updated_at: res.updated_at,
          truck_status: res.truck_status,
          vehicle_no: res.vehicle_no,
          type_truck: res.type_truck,
          gps_vendor: res.gps_vendor,
          city: res.city,
          province: res.province,
          temperature: res.temperature,
          speed: res.speed,
          billable: res.billable,
          engine_car: res.engine_car,
          mileage: res.mileage != null ? res.mileage.toLocaleString() : 0,
          color: res.status_color,
          branch_name: res.branch_name,
          carrier: res.carrier,
          time_server: res.time_server,
          blujay_order: res.blujay_order,
          service: res.service,
          trip: res.trip,
          doc: res.doc,
          load_id: this.load_id,
          equipment: this.equipment,
          load_group: this.load_group,
          driver_name: this.driver_name,
          driver_hp: this.driver_hp,
          date_time: this.date_time,
          address: this.address,
          orders: this.orders,
          last_km_service: res.last_km_service,
          last_service: res.last_service,
          next_pm_service: res.next_pm_service,
          temp: "",
          htmlTable: this.htmlTable,
          deposit: this.deposit,
          ownership: res.ownership,
          self_insurace: "",
          images: images,
          icon: {
            path: 'M 0 0 C 6.625 -7.69 5.825 -2.318 10.132 0.035 L 4.627 -15.371',
            fillColor: res.status_color,
            fillOpacity: 1,
            rotation: res.heading,
            strokeWeight: 1,
            strokeColor: res.status_color,
            scale: 1.5,
          },
        });


      });
    },
  },
  events: {},
  created: function () { },
  mounted() {

    this.fetchBillable.push({

      id: 1,
      name: "LOGISTIK",
    },
      {
        id: 2,
        name: "RENTAL",

      })
    // const start = {
    //   lat: -6.2066575,
    //   lng: 106.7759629,
    // };

    // this.startLocation = start;

    // const end = {
    //   lat: -6.1810517,
    //   lng: 106.7679362,
    // };

    // this.endLocation = end;
    //this.setLocationLatLng();
    //this.locateGeoLocation(5.54829, 95.323753);
    this.fetchIt();
    //this.loading();
    this.loadBranch();
    this.loadStatus();
    this.loadTruck();
    this.loadTruckVehicleNo();
    this.cek_permission_tabs();

    this.loadLocationTable();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>