<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BbmAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Payable Rate Add
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->

                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="d-flex justify-content-between align-items-baseline mb-2"></div>
                    <div class="row mb-3">
                      <div class="col-sm-4">
                        <label for="customer_name " class="col-form-label">Customer</label>
                        <!-- <input type="text" class="form-control" id="customer_name" placeholder="Customer Code"
                          :value="headers.customer_name" disabled /> -->
                        
                          <multiselect @search-change="asyncloadHeader" v-model="rm_payable_rate_header_id" :options="featchCustomerHeader"
                             label="customer_name" track-by="customer_name"
                            placeholder="Please Select">
                            <span slot="noResult">-</span>
                          </multiselect>

                         

                        <div v-if="errors.rm_payable_rate_header_id">
                          <div v-for="error in errors.rm_payable_rate_header_id" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Carier</label>
                          <v-select :options="fetchCarier" v-model="forms.carier" return-object></v-select>
                          <div v-if="errors.carrier_no">
                            <div v-for="error in errors.carrier_no" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Divison</label>
                          <v-select :options="fetchDivision" v-model="forms.division" return-object></v-select>
                          <div v-if="errors.division">
                            <div v-for="error in errors.division" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Load Group</label>
                          <v-select :options="fetchLoadGroup" v-model="forms.load_group" return-object></v-select>
                          <div v-if="errors.load_group">
                            <div v-for="error in errors.load_group" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">Tier</label>
                              <v-select :options="fetchTier" v-model="forms.tier" return-object></v-select>
                              <div v-if="errors.tier">
                                <div v-for="error in errors.tier" :key="error" class="alert alert-primary" role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">All Inclusive</label>
                              <v-select :options="fetchYN" v-model="forms.all_inclusive" return-object></v-select>
                              <div v-if="errors.all_inclusive">
                                <div v-for="error in errors.all_inclusive" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <div class="mb-1">
                              <label for="period_name" class="col-form-label">Round Trip</label>
                              <v-select :options="fetchYN" v-model="forms.round_trip" return-object></v-select>
                              <div v-if="errors.round_trip">
                                <div v-for="error in errors.round_trip" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Origin Location</label>
                          <multiselect @search-change="asyncLocation" v-model="originLocation" :options="fetchLocation"
                            :disabled="originRegion !== null" label="location_code" track-by="location_code"
                            placeholder="Please Select">
                            <span slot="noResult">-</span>
                          </multiselect>

                          <div v-if="errors.origin_location">
                            <div v-for="error in errors.origin_location" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Origin Region</label>
                          <multiselect @search-change="asyncSearchRegion" v-model="originRegion" :options="fetchRegion"
                            :disabled="originLocation !== null" label="region_code" track-by="region_code"
                            placeholder="Please Select">
                            <span slot="noResult">-</span>
                          </multiselect>

                          <div v-if="errors.origin_region">
                            <div v-for="error in errors.origin_region" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Destination Location</label>
                          <multiselect @search-change="asyncLocation" v-model="destLocation" :options="fetchLocation"
                            :disabled="originRegion !== null" label="location_code" track-by="location_code"
                            placeholder="Please Select">
                            <span slot="noResult">-</span>
                          </multiselect>
                          <div v-if="errors.destination_location">
                            <div v-for="error in errors.destination_location" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Destination Region</label>
                          <multiselect @search-change="asyncSearchRegion" v-model="destRegion" :options="fetchRegion"
                            :disabled="originLocation !== null" label="region_code" track-by="region_code"
                            placeholder="Please Select">
                            <span slot="noResult">-</span>
                          </multiselect>

                          <div v-if="errors.destination_region">
                            <div v-for="error in errors.destination_region" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label for="period_name" class="col-form-label">Equipment</label>
                          <!-- <v-select
                            :options="fetchCarier"
                            v-model="carier"
                            return-object
                          ></v-select> -->
                          <multiselect @search-change="asyncSearchSKU" v-model="equipment" @input="changeCekCategoryTruck"
                            :options="fetchSku" placeholder="Please Select" track-by="type_truck" label="type_truck">
                            <span slot="noResult">-</span>
                          </multiselect>
                          <div v-if="errors.equipment">
                            <div v-for="error in errors.equipment" :key="error" class="alert alert-primary" role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Effective Date</label>
                          <date-range-picker style="width: 100%" :locale-data="locale" v-model="effectiveDate" show-dropdowns="true"
                            :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true"  :showDropdowns="true">
                          </date-range-picker>
                          <div v-if="errors.effective_date">
                            <div v-for="error in errors.effective_date" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <label for="period_name" class="col-form-label">Expired Date</label>
                          <date-range-picker style="width: 100%" :locale-data="locale" v-model="expiredDate" show-dropdowns="true"
                            :opens="opens" :singleDatePicker="true" :timePicker24Hour="true" :timePicker="true"  :showDropdowns="true">
                          </date-range-picker>
                          <div v-if="errors.expiration_date">
                            <div v-for="error in errors.expiration_date" :key="error" class="alert alert-primary"
                              role="alert">
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="row">
                            <div class="col-sm-6">
                              <label for="period_name" class="col-form-label">Currency</label>
                              <v-select :options="fetchCurrency" v-model="forms.currency" return-object></v-select>
                              <div v-if="errors.currency">
                                <div v-for="error in errors.currency" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <label for="period_name" class="col-form-label">Cost Basis / Rate Basis</label>
                              <v-select :options="fetchBasis" v-model="forms.cost_basis" return-object></v-select>
                              <div v-if="errors.cost_basis">
                                <div v-for="error in errors.cost_basis" :key="error" class="alert alert-primary"
                                  role="alert">
                                  <i data-feather="alert-circle"></i>
                                  {{ error }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <label for="period_code" class="col-form-label">{{
                          $t("periodCodeTxt")
                        }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="period_code"
                          :placeholder="$t('periodCodeTxt')"
                          v-model="forms.period_code"
                          readonly
                        />
                        <div v-if="errors.period_code">
                          <div
                            v-for="error in errors.period_code"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label for="price" class="col-form-label">{{
                          $t("priceTxt")
                        }}</label>
                        <input
                          type="number"
                          class="form-control"
                          id="price"
                          v-model="forms.price"
                          :placeholder="$t('priceTxt')"
                          required
                        />
                        <div v-if="errors.price">
                          <div
                            v-for="error in errors.price"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="row mb-3">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="truckload-tab" data-bs-toggle="tab" href="#truckload" role="tab"
                            aria-controls="truckload" aria-selected="true">Truck Load</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" v-bind:class="{ disabled: !is_verify }" id="accessorial-tab"
                            data-bs-toggle="tab" href="#accessorial" role="tab" aria-controls="accessorial"
                            aria-selected="true">Accessorial</a>
                        </li>
                      </ul>
                      <div class="tab-content border border-top-0 p-3" id="myTabContent">
                        <div class="tab-pane fade show active" id="truckload" role="tabpanel"
                          aria-labelledby="truckload-tab">
                          <!-- Truck Load Tabs -->
                          <div class="row">
                            <div class="col-sm-4" v-if="false">
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Pool</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Pool"
                                    :value="route?.pool_start_name" disabled />
                                  <div v-if="errors.pool">
                                    <div v-for="error in errors.pool" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Loading</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Loading"
                                    :value="route?.route_origin" disabled />
                                  <div v-if="errors.loading">
                                    <div v-for="error in errors.loading" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Unloading</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Unloading"
                                    :value="route?.route_destination" disabled />
                                  <div v-if="errors.unloading">
                                    <div v-for="error in errors.unloading" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Back to Pool</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Back to Pool"
                                    :value="route?.pool_end_name" disabled />
                                  <div v-if="errors.back_to_pool">
                                    <div v-for="error in errors.back_to_pool" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4" v-if="false">
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">KM (Basic)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="KM (Basic)"
                                     v-model="forms.km_basis" />
                                  <div v-if="errors.km_basis">
                                    <div v-for="error in errors.km_basis" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">KM (Margin)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="KM (Margin)"
                                    v-model="forms.km_margin" />
                                  <div v-if="errors.km_margin">
                                    <div v-for="error in errors.km_margin" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Rasio</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Rasio" v-model="forms.rasio" />
                                  <div v-if="errors.rasio">
                                    <div v-for="error in errors.rasio" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-3 col-form-label">Solar (Liter)</label>
                                <div class="col-sm-9">
                                  <input type="text" class="form-control" placeholder="Solar (Liter)"
                                    v-model="forms.solar_liter"  />
                                  <div v-if="errors.solar_liter">
                                    <div v-for="error in errors.solar_liter" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Rate</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" placeholder="Solar (Rupiah)"
                                    v-model="forms.solar_rupiah" />
                                  <div v-if="errors.solar_rupiah">
                                    <div v-for="error in errors.solar_rupiah" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Lead Time</label>
                                <div class="col-sm-8">
                                  <input type="numeric" class="form-control" disabled placeholder="Lead Time"
                                    v-model="forms.lead_time" />
                                  <div v-if="errors.lead_time">
                                    <div v-for="error in errors.lead_time" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">Service Level</label>
                                <div class="col-sm-8">
                                  <v-select :options="fetchServiceLevel" v-model="forms.service_level">
                                  </v-select>
                                  <div v-if="errors.service_level">
                                    <div v-for="error in errors.service_level" :key="error" class="alert alert-primary"
                                      role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <label class="col-sm-4 col-form-label">No Intermediate Stop</label>
                                <div class="col-sm-8">
                                  <v-select :options="fetchYN" v-model="forms.no_intermediate_stop"
                                    return-object></v-select>
                                  <div v-if="errors.no_intermediate_stop">
                                    <div v-for="error in errors.no_intermediate_stop" :key="error"
                                      class="alert alert-primary" role="alert">
                                      <i data-feather="alert-circle"></i>
                                      {{ error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div>
                                  <button type="button" @click.prevent="verifyData" class="btn btn-twitter pull-right">
                                    Verify
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="accessorial" role="tabpanel" aria-labelledby="accessorial-tab">
                          <div>
                            <!-- <button
                              type="button"
                              @click.prevent="addData"
                              class="btn btn-primary pull-right"
                            >
                              Add
                            </button> -->
                          </div>
                          <br />
                          <br />
                          <br />
                          <div class="row table-responsive">
                            <table class="table">
                              <thead>
                                <th>No</th>
                                <th>Enabled</th>
                                <th>Rate Lane Type</th>
                                <th>Rate Mode</th>
                                <th>Charge Code</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Min Rate</th>
                                <th>Max Rate</th>
                                <th>Weight Rating</th>
                                <th>Auto Apply</th>
                                <th>Action</th>
                              </thead>
                              <tr v-for="(data, index) in accessorial" :key="data.charge_code + index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ data.enabled }}</td>
                                <td>{{ data.rate_lane_type }}</td>
                                <td>{{ data.mode }}</td>
                                <!-- <td>{{ data.charge_code }}</td> -->
                                <td>
                                  <input type="text" class="form-control" v-model="data.charge_code"
                                    :disabled="data.is_default" />
                                </td>
                                <td>{{ data.cost_qty }}</td>
                                <!-- <td>{{ data.rate }}</td> -->
                                <td>
                                  <input type="text" class="form-control" v-model.number="data.rate"
                                     />
                                </td>
                                <td>{{ data.min }}</td>
                                <td>{{ data.max }}</td>
                                <!-- <td>{{ data.weight_rating }}</td> -->
                                <td>
                                  <select :id="'select-weight' + index" v-model="data.weight_rating" class="form-select">
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </select>
                                </td>
                                <td>
                                  <select :id="'select-weight' + index" v-model="data.auto_apply" class="form-select">
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                  </select>
                                </td>

                                <td>


                                  <!-- <a
                                    href="#"
                                    v-if="!data.is_default"
                                    title="Delete"
                                    @click.prevent="
                                      removeField(index, accessorial)
                                    "
                                  >
                                    <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i> 
                                  </a> -->


                                  <a href="#" v-if="data.charge_code == 'UANG PENYEBERANGAN'" title="Edit" @click.prevent="
                                    openModalAccesorial(index, data)
                                    ">
                                    <i class="mdi mdi-pencil" style="font-size:16px"></i>
                                  </a>

                                </td>

                              </tr>
                              <tr v-if="accessorial.length == 0">
                                <td colspan="11" style="text-align: center">
                                  Empty Data
                                </td>
                              </tr>



                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-warning text-black fw-bolder btn-icon-text" @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button v-if="!isLoading" class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>

                      <div v-if="isLoading" class="d-flex align-items-center">
                        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                        <strong>{{ $t("loadingTxt") }}...</strong>
                      </div>

                      <!-- <button
                        disabled
                        class="btn btn-success float-end btn-sm"
                        style="margin-right: 5px"
                        @click="calculate()"
                      >
                        <i class="mdi mdi-calculator"></i>
                        Calculate
                      </button> -->
                    </div>
                  </form>
                </div>
              </div>

              <modal_accesorial_form_payabel :showModal="showModalAccesorialPayable" :categoryTruck="categoryTruck"
                :data="rowPayabelDetail" :indexModal="indexModal" @closeModal="closeMyModal" @pushData="pushDatas">
              </modal_accesorial_form_payabel>

              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import multiselect from "vue-multiselect";
import modalAccesorialFormPayable from "@/components/modal/modalAccesorialFormPayable.vue";


export default {
  name: "PayableRateAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    DateRangePicker,
    multiselect: multiselect,
    modal_accesorial_form_payabel: modalAccesorialFormPayable
  },
  data() {
    return {
      headers: {},
      categoryTruck: '',
      carier: null,
      fetchCarier: [],
      division: null,
      fetchDivision: [],
      loadGroup: null,
      fetchLoadGroup: ["All"],
      fetchBasis: [],
      fetchTier: [],
      fetchYN: ["Yes", "No"],
      fetchED: ["ENABLED", "DISABLED"],
      fetchServiceLevel: ["TL STANDARD"],
      fetchCurrency: [],
      truckLoad: {},
      fetchLocation: [],
      fetchRegion: [],
      // originLocation: null,
      originLocation: {},
      originRegion: null,
      // destLocation: null,
      destLocation: {},
      destRegion: null,
      fetchSku: [],
      // equipment: null,
      equipment: {},

      locale: {
        format: "dd mmm yyyy H:MM:ss",
      },
      effectiveDate: {
        startDate: null,
        endDate: null,
      },
      expiredDate: {
        startDate: null,
        endDate: null,
      },
      opens: "center",
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      companyCode: "",
      fetchCompany: [],
      fetchCompanyNotLinc: [],
      forms: {
        division: "All",
        load_group: "All",
        tier: "Tier 1",
        all_inclusive: "Yes",
        round_trip: "No",
        service_level: "TL STANDARD",
        currency: "IDR",
        cost_basis: "FLT",
        no_intermediate_stop: "No",
        lead_time: 1,
      },
      featchCustomerHeader:[],
      rm_payable_rate_header_id:'',
      company: [],
      accessorial: [],
      ratio: null,
      ujo: null,
      route: null,
      is_verify: false,
      indexModal: null,
      showModalAccesorialPayable: false,
      rowPayabelDetail: {},
    };
  },
  computed: {
    getLeadTime() {
      let leadTime = 3;
      if (this.forms?.lead_time && this.forms?.lead_time <= 3) {
        leadTime = this.forms?.lead_time;
      }
      return leadTime;
    },


  },
  watch: {
    "forms.lead_time": function () {
      
      if (!this.$route.query.detailId && !this.$route.query.detailId !== "") {
        this.createAccessorialData();
      }
    },
  },
  methods: {

    loadHeader() {
      const baseURI = this.$settings.endPoint + "rm-payable-rate-header";
      return this.$http
        .get(
          baseURI
        )
        .then((response) => {
          this.featchCustomerHeader = response.data.datas.data;
        });
    },

  
    asyncloadHeader(ev) {
      const baseURI = this.$settings.endPoint + "rm-payable-rate-header?customer_name="+ev;
      return this.$http
        .get(
          baseURI
        )
        .then((response) => {
          this.featchCustomerHeader = response.data.datas.data;
        });
    },

    restoreToNumber(formatted) {
      let formattedToNumber = "";
      // Mengembalikan ke format number standar JavaScript
      let check = this.checkDecimalZero(formatted);
      if (check) {
        formattedToNumber = parseFloat(formatted.replace(/\./g, '').replace(',00', ''));
      } else {
        formattedToNumber = formatted;
      }

      return formattedToNumber;
    },

    restoreToNumber_(formatted) {
      let formattedToNumber = "";
      // Mengembalikan ke format number standar JavaScript
      let check = this.checkDecimalZero(formatted);
      if (check) {
        formattedToNumber = parseFloat(formatted.replace(',00', ''));
      } else {
        formattedToNumber = formatted;
      }

      return formattedToNumber;
    },

    checkDecimalZero(number) {
      // Mengonversi angka menjadi string untuk memeriksa karakter desimal
      const numberString = number.toString();

      // Mengecek apakah karakter terakhir adalah ",00"
      if (numberString.slice(-3) === ",00") {
        return true;
      }

      return false;
    },

    getAllFormated(name_field, value) {


      let formatted = "";
      if (value != null) {


        formatted = parseFloat(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        if (name_field == 'solar_liter') {
          formatted = parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }

        if (formatted.indexOf(',') === -1) {
          formatted += ',00';
        } else {
          let decimalPart = formatted.split(',')[1];
          if (decimalPart.length === 1) {
            formatted += '0';
          }
        }


        this.forms[name_field] = formatted;

      } else {
        this.forms[name_field] = '0,00'
      }

      console.log(this.forms[name_field]);
      return this.forms[name_field]

    },


    getAllFormatedDetail(index, name_field, value) {

      let isNegative = value < 0;
      let absoluteValue = Math.abs(value);

      //console.log(this.accessorial[index][name_field]);

      let formatted = parseFloat(absoluteValue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (formatted.indexOf(',') === -1) {
        formatted += ',00';
      } else {
        let decimalPart = formatted.split(',')[1];
        if (decimalPart.length === 1) {
          formatted += '0';
        }
      }

      formatted = isNegative ? '-' + formatted : formatted;
      console.log(formatted);
      this.accessorial[index].rate = formatted;
      //this.accessorial[index].rate=formatted;

      // return  this.accessorial[index];

    },


    getAllFormatedDetail2(index, name_field, value) {

      let isNegative = value < 0;
      let absoluteValue = Math.abs(value);

      //console.log(this.accessorial[index][name_field]);

      let formatted = parseFloat(absoluteValue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      if (formatted.indexOf(',') === -1) {
        formatted += ',00';
      } else {
        let decimalPart = formatted.split(',')[1];
        if (decimalPart.length === 1) {
          formatted += '0';
        }
      }

      formatted = isNegative ? '-' + formatted : formatted;

      return formatted


    },

    getSolarLiter(ev) {


      this.forms.solar_liter = parseFloat(ev).toFixed(2);

      return this.forms.solar_liter

    },


    openModalAccesorial(index, row) {

      this.indexModal = index;
      this.showModalAccesorialPayable = true;
      this.rowPayabelDetail = row;

    },

    pushDatas(row) {
      console.log(row)
      this.accessorial[row.index].rate = row.rate
    },

    closeMyModal() {
      this.showModalAccesorialPayable = false;
      //this.loadDetail();
    },

    verifyData() {
      this.errors = [];
      let error = false;
      this.is_verify = false;
      
      if (Object.entries(this.equipment).length==0) {
        error = true;
        this.errors["equipment"] = ["Equipment Wajib Diisi"];
      }
      if (Object.entries(this.originLocation).length==0) {
        error = true;
        this.errors["origin_location"] = [
          "Origin Location / Origin Region Wajib Diisi",
        ];
      }
      if (Object.entries(this.destLocation).length==0) {
        error = true;
        this.errors["destination_location"] = [
          "Destination Location / Region Wajib Diisi",
        ];
      }
      if (!error) {
        // console.log("safe point");
        let datas = {
          origin_location: this.originLocation?.location_code,
          origin_region: this.originRegion?.region_code,
          destination_location: this.destLocation?.location_code,
          destination_region: this.destRegion?.region_code,
          equipment: this.equipment?.type_truck,
        };
        const baseURI =
          this.$settings.endPoint + `rm-payable-rate-header/verify`;
        return this.$http
          .post(baseURI, datas)
          .then((response) => {
            let data = response.data.datas;
            // console.log(data);
            if (data.ujo && data.ujo?.ratio) {
              // console.log("ujo dapet");
              this.ujo = data.ujo;
              this.forms.solar_rupiah = this.ujo.fuel_price;
              this.forms.solar_liter = this.ujo.fuel_liter;
              this.ratio = data.ujo.ratio;
              this.ratio['detail'] = data.ujo.ratio_detail;
              // this.route = data.route;
              // this.lead_time = this.route.lead_time;
              this.is_verify = true;
              // this.success('Verify Success');
              // this.createAccessorialData();

              if(data.route!=null){
                this.route = data.route;
                this.lead_time = this.route.lead_time;
                this.success('Verify Success');
                this.createAccessorialData();
              }else{
                this.error("Route Not Complete");
              } 

            } else {
              this.error("Ujo Not Complete");
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                if (this.errors.message) {
                  this.error(this.errors.message);
                } else {
                  this.resultError(error.response.data.errors);
                }
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            //this.resetForm();
          });
      } else {
        this.resultError(this.errors);
      }
    },
    addData() {
      this.accessorial.push({
        charge_code: "charge code " + this.accessorial.length,
        rate: 0,
        weight_rating: 0,
        auto_apply: 0,
        min: 0,
        max: 0,
        cost_qty: 0,
        mode: "TL",
        enabled: "ENABLED",
        rate_lane_type: "ORIGIN TO DESTINATION",
        is_default: false,
      });
    },
    equipmentSelected(ev) {
      this.loadRatio(ev.type_truck);
    },
    async createAccessorialData() {
      // console.log("Created Accessorial Data");
      this.accessorial = await this.resetAccessorial();
      if (this.ratio) {
        this.accessorial.push({
          charge_code: "KOMISI SUPIR",
          rate: this.ratio?.driver_commision * this.getLeadTime,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });

        this.accessorial.push({
          charge_code: "DEPOSIT",
          rate: this.ratio?.driver_deposit * this.getLeadTime,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });
        this.accessorial.push({
          charge_code: "SELF_INSURANCE",
          rate: this.ratio?.driver_self_insurance,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });
      }
      if (this.ujo) {
        this.accessorial.push({
          charge_code: "MEL",
          rate: this.ujo?.mel ? this.ujo?.mel : 0,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });
        this.accessorial.push({
          charge_code: "UANG PENYEBERANGAN",
          rate: this.ujo?.ferry ? this.ujo?.ferry : 0,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });
        this.accessorial.push({
          charge_code: "TOLL",
          rate: this.ujo?.toll_price ? this.ujo?.toll_price : 0,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });


        this.accessorial.push({
          charge_code: "ALFA_KOSONGAN_BL",
          rate: this.ujo?.alfa_kosongan_bl ? this.ujo?.alfa_kosongan_bl : 0.01,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });

        this.accessorial.push({
          charge_code: "NST_BIAYA BONGKAR/MUAT",
          rate: this.ujo?.nst_biaya_bongkar ? this.ujo?.nst_biaya_bongkar : 0.01,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });

        this.accessorial.push({
        charge_code: "NST_INSENTIF RIT 1",
          rate: this.ujo?.nst_insentif_rit1 ? this.ujo?.nst_insentif_rit1 : 0.01,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });
        



        this.accessorial.push({
          charge_code: "NST_KOMISI_SUPIR",
          rate: this.ujo?.nst_komisi_supir ? this.ujo?.nst_komisi_supir : 0.01,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });

        this.accessorial.push({
          charge_code: "NST_BIAYA_TERPAL",
          rate: this.ujo?.nst_biaya_terpal ? this.ujo?.nst_biaya_terpal : 0.01,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });

        this.accessorial.push({
          charge_code: "NST_MEL",
          rate: this.ujo?.nst_mel? this.ujo?.nst_mel : 0.01,
          weight_rating: 0,
          auto_apply: 0,
          min: 0,
          max: 0,
          cost_qty: 0,
          mode: "TL",
          enabled: "ENABLED",
          rate_lane_type: "ORIGIN TO DESTINATION",
          is_default: true,
        });

      }
     
    },
    resetAccessorial() {
      let datas = [];
      for (let i = 0; i < this.accessorial.length; i++) {
        if (!this.accessorial[i].is_default) {
          datas.push(this.accessorial[i]);
        }
      }
      return datas;
    },
    loadRatio(typeTruck) {
      const baseURI =
        this.$settings.endPoint + `rm-ratio-master?truck_category=${typeTruck}`;
      return this.$http.get(baseURI).then((response) => {
        let data = response.data.datas.data;
        if (data.length >= 1) {
          this.ratio = data[0];
          // console.log("Rasio Picked : " + this.ratio.ratio_name);
        } else {
          this.error("Ratio is Empty");
        }
      });
    },
    loadLocation() {
      const baseURI =
        this.$settings.endPoint +
        `location?status=ENABLED&company_name=` +
        this.headers?.customer_name;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        this.fade(false);
      });
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    asyncLocation(ev) {
      const baseURI =
        this.$settings.endPoint +
        "location?status=ENABLED&company_name=" +
        this.rm_payable_rate_header_id?.customer_name +
        "&location_name=" +
        ev;
      this.fetchLocation = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
      });
    },
    loadRegion() {
      const baseURI = this.$settings.endPoint + `region?status=ENABLED`;
      this.fetchRegion = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },
    asyncSearchRegion(ev) {
      const baseURI =
        this.$settings.endPoint + `region?region_code=${ev}&status=ENABLED`;
      this.fetchRegion = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchRegion = response.data.datas.data;
      });
    },
    loadCarier() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=carer`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCarier = response.data.datas;
      });
    },
    loadTier() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmtier`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchTier = response.data.datas;
      });
    },
    loadSku() {
      const baseURI = this.$settings.endPointCt + `type-truck`;
      this.fade(true);
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
        this.fade(false);
      });
    },
    asyncSearchSKU(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=${ev}&sort_by=type_truck_id`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchSku = response.data.datas.data;
      });
    },
    loadBasis() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmbas`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchBasis = response.data.datas;
      });
    },
    loadCurrency() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmcur`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCurrency = response.data.datas;
      });
    },
    loadDivision() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=brmdiv`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchDivision = response.data.datas;
      });
    },
    loadDetail() {
      var params = this.$onBehind(this.$route.params.id);
      const baseURI =
        this.$settings.endPoint + "rm-payable-rate-header/detail/" + params[0];
      return this.$http.get(baseURI).then((response) => {
        // &expired_date=${this.serverParams.columnFilters.expired_date}
        this.headers = response.data.datas.header;
      });
    },
    resetForm() {
      this.forms.period_name = "";
      this.forms.price_gap = "";
      this.forms.price = "";
      this.forms.period_code = "";
      this.effectiveDate = {
        startDate: null,
        endDate: null,
      };
      this.expiredDate = {
        startDate: null,
        endDate: null,
      };
    },


    loadDetailCopy(detailId) {
     
     const baseURI =
       this.$settings.endPoint + "rm-payable-rate-header/detail-detail/" + detailId;
     return this.$http.get(baseURI).then((response) => {
       // &expired_date=${this.serverParams.columnFilters.expired_date}
       this.headers = response.data.datas.header;
       this.forms = response.data.datas;
      //  this.getAllFormated('solar_rupiah', this.forms.solar_rupiah)
      //  this.getAllFormated('km_basis', this.forms.km_basis)
      //  this.getAllFormated('km_margin', this.forms.km_margin)
      //  this.getAllFormated('solar_liter', this.forms.solar_liter)
       this.forms.rasio = this.forms.ujo?.ratio_detail_value
       this.forms.all_inclusive=this.forms.all_inclusive==1 ? 'Yes' : 'No';

       if (this.forms.accessorial) {
         // let acc = this.forms.accessorial;
         // acc.forEach()
       }
       this.accessorial = response.data.datas.accessorial;

      //  for (let index = 0; index < this.accessorial.length; index++) {
      //    // console.log(this.forms.accessorial[index].rate)
      //    this.accessorial[index].rate = this.getAllFormatedDetail2(index, 'rate', this.accessorial[index].rate);

      //  }

       this.rm_payable_rate_header_id={
          rm_payable_rate_header_id:this.forms.header.rm_payable_rate_header_id,
          customer_name:this.forms.header.customer_name
       },

       this.forms.carier = this.forms.carrier_no + "-" + this.forms.carrier_name;
       let oriLat = "";
       let oriLang = "";
       let destLang = "";
       let destLat = "";

       if (this.forms.origin_location) {
         this.originLocation = {
           location_code: this.forms.origin_location,
           latitude: oriLat,
           longitude: oriLang,
         }
       }
       if (this.forms.destination_location) {
         this.destLocation = {
           location_code: this.forms.destination_location,
           latitude: destLat,
           longitude: destLang,
         }
       }
       if (this.forms.origin_region) {
         this.originRegion = {
           region_code: this.forms.origin_region
         }
       }
       if (this.forms.destination_region) {
         this.destRegion = {
           region_code: this.forms.destination_region
         }
       }
       this.equipment = {
         type_truck: this.forms.equipment
       }

       this.loadCekCategoryTruck(this.forms.equipment);

       this.effectiveDate = {
         startDate: this.forms.effective_date == null ? null : new Date(this.forms.effective_date),
         endDate: this.forms.effective_date == null ? null : new Date(this.forms.effective_date),
       };
       this.expiredDate = {
         startDate: this.forms.expiration_date == null ? null : new Date(this.forms.expiration_date),
         endDate: this.forms.expiration_date == null ? null : new Date(this.forms.expiration_date),
       };


       this.is_verify = true;
     });
   },


   loadCekCategoryTruck(type_truck) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + type_truck;
      return this.$http.get(baseURI).then((response) => {

        this.categoryTruck = response.data.datas.data.length > 0 ? response.data.datas.data[0].category : '';

      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmFuelMasterController") {
          rolePermision = permision;
        }
      });

      this.permision_role = rolePermision;
    },

    backForm() {
      window.location.href = "/payable-rate/rute";
    },

    changeCekCategoryTruck(ev) {
      this.categoryTruck = ev.category;
    },


    submitData() {
      // this.forms.company_id = this.company.company_id;
      this.forms.effective_date = moment(this.effectiveDate.startDate).format(
        "YYYY-MM-DD"
      );
      this.forms.expiration_date = moment(this.expiredDate.startDate).format(
        "YYYY-MM-DD"
      );
      this.forms.origin_location = this.originLocation?.location_code
        ? this.originLocation?.location_code
        : "";
      this.forms.destination_location = this.destLocation?.location_code
        ? this.destLocation?.location_code
        : "";
      this.forms.origin_region = this.originRegion?.region_code
        ? this.originRegion?.region_code
        : "";
      this.forms.destination_region = this.destRegion?.region_code
        ? this.destRegion?.region_code
        : "";
      this.forms.equipment = this.equipment?.type_truck
        ? this.equipment?.type_truck
        : "";
      this.forms.geography_origin = "";
      this.forms.geography_destination = "";
      if ((this.originLocation?.latitude && this.originLocation?.latitude !== '') && (this.originLocation?.longitude && this.originLocation?.longitude !== '')) {
        this.forms.geography_origin = this.originLocation?.latitude + ',' + this.originLocation?.longitude;
      }
      if ((this.destLocation?.latitude && this.destLocation?.latitude !== '') && (this.destLocation?.longitude && this.destLocation?.longitude !== '')) {
        this.forms.geography_destination = this.destLocation?.latitude + ',' + this.destLocation?.longitude;
      }
      this.forms.carrier_no = "";
      this.forms.carrier_name = "";
      let carier = this.forms.carier;
      if (carier) {
        carier = carier.split("-");
        this.forms.carrier_no = carier[0];
        this.forms.carrier_name = carier[1];
      }
     
      this.forms.rm_payable_rate_header_id = this.rm_payable_rate_header_id.rm_payable_rate_header_id;


      let acc = [];
      let a = this.forms;
      this.accessorial.forEach(function (accs) {
        const data = {
          carrier_no: a.carrier_no,
          carrier_name: a.carrier_name,
          origin_location: a.origin_location,
          origin_region: a.origin_region,
          destination_location: a.destination_location,
          destination_region: a.destination_region,
          geography_destination: a.geography_destination,
          geography_origin: a.geography_origin,
          charge_code: accs.charge_code,
          tier: a.tier,
          division: a.division,
          load_group: a.load_group,
          mode: accs.mode,
          equipment: a.equipment,
          effective_date: a.effective_date,
          expired_date: a.expiration_date,
          basis: a.basis ? a.basis : "",
          rate: accs.rate,
          currency: a.currency,
          cost_qty: accs.cost_qty,
          min: accs.min,
          max: accs.max,
          weight_rating: accs.weight_rating,
          auto_apply: accs.auto_apply,
          acd: "",
          rm_payable_accessorialcol: "",
          rate_lane_type: accs.rate_lane_type,
          enabled: accs.enabled,
        };
        acc.push(data);
      });
      this.forms.accessorial = acc;


      this.forms.all_inclusive=this.forms.all_inclusive=='Yes' ? 1 : 0;

      // this.forms.km_basis = this.restoreToNumber(this.forms.km_basis)
      // this.forms.solar_rupiah = this.restoreToNumber(this.forms.solar_rupiah)
      // this.forms.km_margin = this.restoreToNumber(this.forms.km_margin)
      // this.forms.solar_liter = this.restoreToNumber(this.forms.solar_liter)

      // console.log(this.forms);
      this.fade(true);
      const baseURI =
        this.$settings.endPoint + "rm-payable-rate-header/create-detail";
      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            //var params = this.$onRandom(response.data.datas.rm_payable_rate_header_id);
            var params = this.$onRandom(response.data.datas.rm_payable_rate_detail_id);
          
            // console.log(response.data.datas);
            window.location.href =
              "/payable-rate/rute/detail/" +
              params +
              "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              if (this.errors.message) {
                this.error(this.errors.message);
              } else {
                this.resultError(error.response.data.errors);
              }
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadCarier();
    this.loadDivision();
    this.loadLocation();
    this.loadRegion();
    this.loadTier();
    this.loadCurrency();
    this.loadBasis();
    this.loadSku();
    this.loadHeader();
    //this.loadDetail();

    if (this.$route.query.detailId && this.$route.query.detailId !== "") {
      const detailId = this.$onBehind(this.$route.query.detailId);
      this.loadDetailCopy(detailId[0]);
    }
    // this.createAccessorialData();
    // this.loadCompany();
    // this.loadCompanyNotLinc();
  },
};
</script>
<style scoped></style>
